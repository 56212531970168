import React, { useCallback, useState } from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import forIn from 'lodash/forIn'
import { useForm } from 'react-hook-form'

import Layout from '@c/layout'
import Breadcrumb from '@c/breadcrumb'
import Book from '@c/book'
import AuthorCard from '@c/author-card'
import FormInput from '@c/form-input'
import FormCheckbox from '@c/form-checkbox'
import Modal from '@c/modal'
import ShareBox from '@c/share-box'
import BookModel from '@m/Book'
import makeSlug from '@u/make-slug'

import './detail.css'

const GATSBY_BOOK_SUBSCRIBE_FORM_POST_URL =
  process.env.GATSBY_BOOK_SUBSCRIBE_FORM_POST_URL
const sectors = [
  'Advertising and Marketing',
  'Agriculture, Hunting and Fishing',
  'Automotive',
  'Chemicals, Petroleum, Rubber and Plastics',
  'Construction',
  'Culture, Art and Design',
  'Digital Entertainment & Hobby & Toys',
  'E-Commerce / Marketplace',
  'Education',
  'Electronic Appliances',
  'Energy',
  'Environment',
  'Fashion and Apparel',
  'Finance',
  'FMCG',
  'Food',
  'Furniture and Home Textiles',
  'Glass, Cement and Soil',
  'Health',
  'Information Technologies',
  'Insurance',
  'Media, Communication and Publishing',
  'Mining & Metal',
  'Online Market',
  'Paper and Paper Products',
  'Real Estate',
  'Sport and Fitness',
  'Tourism and Transportation',
  'Other',
]
const Page = ({ data, pageContext }) => {
  const { book, pageSettings } = data
  const { author, coverTitleFirst, title } = book
  const model = new BookModel(book)
  const [isOtherFieldRequired, setIsOtherFieldRequired] = useState(false)
  const [sectorTouched, setSectorTouched] = useState(false)
  const form = useForm()
  form.register('sector', { required: true })

  const [state, setState] = useState(() => ({
    modalContent: '',
    modalToggle: 'd-none'
  }))

  const onSubscribe = useCallback(
    (e) => {
      e.preventDefault()
      const url = GATSBY_BOOK_SUBSCRIBE_FORM_POST_URL
      const formData = new FormData()
      let contactListId = 'contactlist_' + book.autopilotappContactListId

      forIn(form.getValues(), (value, key) => formData.append(key, value))
      formData.append('title', title)
      formData.append('contactListId', contactListId)
      setState({ modalToggle: 'modal', modalContent: 'Please wait...' })

      fetch(url, {
        method: 'post',
        body: formData
      })
        .then(async (response) => ({ response, body: await response.text() }))
        .then(() => {
          setState({ modalToggle: 'd-none' })
        })
        .then(() => {
          form.reset()
          setIsOtherFieldRequired(false)
          setState({
            modalToggle: 'modal',
            modalContent:
              'You can see our report by clicking on the link sent to your e-mail address.'
          })
          if (typeof window !== 'undefined' && book?.file?.url) {
            window.open(book?.file?.url, '_blank')
          }
        })
        .catch(() => {
          setState({
            modalToggle: 'modal',
            modalContent: 'An error occurred, please try again.'
          })
        })
        .finally(() => {
          setTimeout(() => {
            setState({ modalToggle: 'd-none' })
          }, 3000)
        })
    },
    [form, title]
  )

  const parentPages = [
    {
      url: makeSlug.generic(pageContext.locale, pageSettings.resourcesSlug),
      title: pageSettings.resourcesTitle
    },
    {
      url: makeSlug.generic(
        pageContext.locale,
        pageSettings.resourcesSlug,
        pageSettings.resourcesBookSlug
      ),
      title: pageSettings.resourcesBookTitle
    }
  ]

  const url = `https://zeo.org${model.getPath(pageSettings)}`
  const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    url
  )}`
  const twitterLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    title
  )}&url=${encodeURIComponent(url)}`
  const linkedinLink = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    url
  )}`
  const whatsappLink = `whatsapp://send?text=${encodeURIComponent(url)}`
  const mailLink = `mailto:?subject=${encodeURIComponent(
    title
  )}&body=${encodeURIComponent(url)}`

  const onSectorChange = (ev) => {
    const val = ev.target.value
    setSectorTouched(true)
    if (val === 'Other') {
      setIsOtherFieldRequired(true)
      form.register('other_sector', { required: 'true' })
    } else {
      setIsOtherFieldRequired(false)
      form.unregister('other_sector')
    }
    form.setValue('sector', val)
  }
  return (
    <Layout
      locale={pageContext.locale}
      seo={{ ...model.getSeoComponentProps(pageSettings) }}
    >
      {/* BREADCRUMB */}
      {
        book.display_breadcrumb && (
          <div className="container mb-60">
            <div className="row">
              <div className="col-12">
                <Breadcrumb
                  className="resource-detail-breadcrumb"
                  parentPages={parentPages}
                  currentPage={title}
                />
              </div>
            </div>
          </div>
        )
      }

      <div className="container">
        <div className="row">
          <div className="col-12 offset-lg-1 col-lg-5 col-xl-4 order-lg-1">
            <form onSubmit={onSubscribe.bind(this)}>
              <Book
                photo={get(book, 'coverImage')}
                alt={get(book, 'coverImage.alt') || ''}
                title={coverTitleFirst}
              />
              <div className="py-24 bg-soft-blue flex-column align-items-center justify-content-center">
                <div className="col-12 my-24">
                  <FormInput
                    name="name"
                    placeholder="Name"
                    required={true}
                    ref={form.register()}
                  />
                </div>
                <div className="col-12 my-24">
                  <FormInput
                    name="lastname"
                    placeholder="Surname"
                    required={true}
                    ref={form.register()}
                  />
                </div>
                <div className="col-12 mb-24">
                  <FormInput
                    name="companyName"
                    placeholder="Company Name"
                    required={true}
                    ref={form.register()}
                  />
                </div>
                <div className="col-12 mb-24">
                  <FormInput
                    name="jobTitle"
                    placeholder="Title"
                    required={true}
                    ref={form.register()}
                  />
                </div>
                <div className="col-12 mb-24">
                  <div className="form-select form--required">
                    <select
                      name="sector"
                      style={{ color: sectorTouched ? 'black' : '#757575' }}
                      required
                      onChange={onSectorChange}
                      onBlur={onSectorChange}
                      defaultValue={''}
                    >
                      <option disabled value="">
                        {' '}
                        Industry{' '}
                      </option>
                      {sectors.map((type) => (
                        <option
                          style={{ color: 'black' }}
                          key={type}
                          value={type}
                        >
                          {type}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {isOtherFieldRequired && (
                  <div className="col-12 mb-24">
                    <FormInput
                      name="other_sector"
                      placeholder="Industry"
                      required
                      onChange={(ev) =>
                        form.setValue('other_sector', ev.target.value)
                      }
                      onBlur={(ev) =>
                        form.setValue('other_sector', ev.target.value)
                      }
                    />
                  </div>
                )}
                <div className="col-12 mb-24">
                  <FormInput
                    type="email"
                    name="email"
                    placeholder="E-mail Address"
                    required={true}
                    ref={form.register()}
                  />
                </div>
                <div className="col-12 fs-small mb-24">
                  <FormCheckbox
                    name="checkbox"
                    ref={form.register()}
                    required={true}
                  >
                    I agree to receive information emails.
                  </FormCheckbox>
                </div>
                <div className="col-12 d-flex justify-content-end mb-24">
                  <button type="submit" className="btn btn--dark">
                    Download now
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-12 col-lg-6 col-xl-7 mt-40 mt-lg-0">
            <h1>{book.title2}</h1>

            {/* start-meta */}
            <div className="d-flex align-items-center flex-wrap mt-40">
              {author && (
                <AuthorCard
                  photo={get(author, 'profileImage')}
                  alt={get(author, 'profileImage.alt') || ''}
                  name={`${author.firstName} ${author.lastName}`}
                  nickname={author.nickname}
                  categoryBase={makeSlug.generic(
                    pageContext.locale,
                    pageSettings.resourcesSlug,
                    pageSettings.resourcesBookSlug,
                    pageSettings?.categorySlug
                  )}
                  categories={book.categories}
                  locale={pageContext.locale}
                />
              )}
              <span className="ml-auto">
                <ShareBox
                  facebookLink={facebookLink}
                  twitterLink={twitterLink}
                  mailLink={mailLink}
                  whatsappLink={whatsappLink}
                  linkedinLink={linkedinLink}
                />
              </span>
            </div>
            {/* end-meta */}

            <div
              className="mt-40"
              dangerouslySetInnerHTML={{ __html: book.shortDescription }}
            ></div>
            <div
              className="mt-40 book-content"
              dangerouslySetInnerHTML={{ __html: book.description }}
            ></div>
          </div>
        </div>
        <Modal
          modalContent={state.modalContent}
          modalToggle={state.modalToggle}
        />
      </div>
    </Layout>
  )
}

export default Page

export const query = graphql`
  query ($bookId: String, $locale: String) {
    book: datoCmsBook(id: { eq: $bookId }) {
      _allSlugLocales {
        locale
        value
      }
      metaTags {
        ...MetaTags
      }
      locale
      slug
      title
      title2
      coverTitleFirst
      shortDescription
      autopilotappContactListId
      description
      categories {
        title
        slug
      }
      file {
        url
      }
      coverImage {
        alt
        asset {
          localImage {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }

      author {
        firstName
        lastName
        nickname
        profileImage {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 30)
              }
            }
          }
        }
      }
    }

    pageSettings: datoCmsPageSetting(locale: { eq: $locale }) {
      _allResourcesSlugLocales {
        locale
        value
      }
      _allResourcesBookSlugLocales {
        locale
        value
      }
      resourcesTitle
      resourcesSlug
      resourcesBookTitle
      resourcesBookSlug
      categorySlug
    }

    # Translations
    translations: datoCmsTranslation(locale: { eq: $locale }) {
      ...TranslationsFragment
    }
  }
`
