import React from 'react'
import { Link } from 'gatsby'

import './index.css'
import ProjectImage from '../project-image'

function BookContainer({ url, children }) {
  if (url) {
    return <Link to={url}>{children}</Link>
  }
  return <div>{children}</div>
}

function Book({ title, titleSecond, coverDescription, photo, url }) {
  return (
    <div className="book u-shadow">
      <BookContainer url={url} className="d-block">
        <div className="book-title px-24 py-32">
          <h5 className="h4 fw-head-normal">
            <div className="fw-head-semibold">{title}</div>
            <div>{titleSecond}</div>
          </h5>
          {coverDescription && (
            <small className="mt-8">{coverDescription}</small>
          )}
        </div>
        <figure className="book-figure">
          {photo && <ProjectImage image={photo} />}
        </figure>
      </BookContainer>
    </div>
  )
}

export default Book
