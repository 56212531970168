import React, { useState } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'

function FormCheckboxWithRef(
  { required, defaultChecked, onChange, withBorder, name, children },
  ref
) {
  const [checked, setChecked] = useState(defaultChecked)

  const onToggle = event => {
    const check = event.target.checked
    setChecked(check)
    onChange(check)
  }

  return (
    <label
      className={cn('checkbox', { checked, 'checkbox-border': withBorder })}
    >
      <span className="checkbox-icon">
        <input
          type="checkbox"
          defaultChecked={checked}
          onChange={onToggle}
          name={name}
          ref={ref}
          required={required}
          tabIndex="-1"
        />
      </span>

      {children && <span className="checkbox-text ml-8">{children}</span>}
    </label>
  )
}

const FormCheckbox = React.forwardRef(FormCheckboxWithRef)

FormCheckbox.defaultProps = {
  defaultChecked: false,
  withBorder: false,
  onChange: () => {}
}

FormCheckbox.propTypes = {
  defaultChecked: PropTypes.bool,
  withBorder: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.node
}

export default FormCheckbox
