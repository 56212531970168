import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import getContentByLocale from '@u/get-content-by-locale'
import makeSlug from '@u/make-slug'

import './index.css'
import ProjectImage from '../project-image'

const query = graphql`
  query {
    siteSettings: allDatoCmsSiteSetting {
      edges {
        node {
          locale
          authorCardInText
        }
      }
    }
    teamPage: allDatoCmsTeamPage {
      edges {
        node {
          locale
          slug
          team {
            members {
              nickname
            }
          }
        }
      }
    }
  }
`

// This component does not link to a profile page if the given nickname is not
// included in team page's members field.
function AuthorCard({
  photo,
  locale,
  nickname,
  name,
  categoryBase = '/',
  categories
}) {
  const {
    siteSettings: { edges: siteSettingsEdges },
    teamPage: { edges: teamPageEdges }
  } = useStaticQuery(query)
  const siteSettings = getContentByLocale(
    locale,
    siteSettingsEdges,
    'node.locale'
  )?.node
  const teamPage = getContentByLocale(locale, teamPageEdges, 'node.locale')
    ?.node
  const linkToProfile =
    (teamPage &&
      teamPage.team[0]?.members.find(m => m.nickname === nickname) &&
      true) ||
    false

  return (
    <div className="author-card fs-small d-flex flex-wrap align-items-center justify-content-start">
      {linkToProfile ? (
        <Link
          to={makeSlug.generic(locale, teamPage.slug, nickname)}
          className="d-flex flex-wrap align-items-center"
        >
          {photo && <ProjectImage className="author-img small" image={photo} />}
          <span className="ml-8">{name}</span>
        </Link>
      ) : (
        <div className="d-flex align-items-center">
          {photo && <ProjectImage className="author-img small" image={photo} />}
          <span className="ml-8">{name}</span>
        </div>
      )}
      &nbsp;
      {categories && (
        <>
          {categories.length > 0 && ` ${siteSettings.authorCardInText} `}
          {categories.map((category, i) => (
            <span key={category.slug}>
              <Link to={categoryBase + '/' + category.slug} className="ml-4">
                <span className="fw-text-bold">{category.title}</span>
              </Link>
              {categories.length > 1 && !(i === categories.length - 1) && ','}
            </span>
          ))}
        </>
      )}
    </div>
  )
}

export default AuthorCard
